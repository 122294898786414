import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DrawerType } from '@common/enums';

@Component({
  selector: 'app-drawer-common-footer',
  templateUrl: './drawer-common-footer.component.html',
  styleUrls: ['./drawer-common-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerCommonFooterComponent {
  @Input() pending = false;
  @Input() invalid = false;
  @Output() cancel = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();

  @Input() set drawerType(value: DrawerType) {
    this.saveButtonLabel = value === DrawerType.Add ? 'COMMON.ADD' : 'COMMON.SAVE';
  }

  @Input() saveButtonLabel = 'COMMON.OK';
}
