import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UsersApiService } from '@services/api';
import { Language } from '@common/dto';

@Component({
  selector: 'app-language-changer',
  templateUrl: './language-changer.component.html',
  styleUrls: ['./language-changer.component.scss'],
})
export class LanguageChangerComponent implements OnInit, OnDestroy {
  control = new UntypedFormControl(this.translate.currentLang);
  LanguageEnum = Language;
  private destroy$ = new Subject();
  constructor(public translate: TranslateService, private usersApi: UsersApiService) {}

  ngOnInit(): void {
    this.subscribeToControl().subscribe();
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private subscribeToControl() {
    return this.control.valueChanges.pipe(
      tap((lang) => this.translate.use(lang)),
      switchMap((lang) => this.usersApi.updateCulture(lang)),
      takeUntil(this.destroy$),
    );
  }
}
