import { RangeDateDto } from '@common/dto';

export function formatRangeDate(date: RangeDateDto): RangeDateDto {
  const resp = date
    ? {
        start: dateToISOLikeButLocal(date?.start),
        end: date?.end.toString().endsWith('T23:59:59')
          ? dateToISOLikeButLocal(date?.end)
          : dateToISOLikeButLocal(date?.end, true),
      }
    : null;

  return resp;
}

export function dateToISOLikeButLocal(d, plusOneDay?) {
  const date = new Date(d);
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs + (plusOneDay ? 24 * 60 * 60 * 1000 - 1 : 0);
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19);
  return isoLocal;
}
