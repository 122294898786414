import { TablePageFilter } from '../table-page-filter-base';
import { IFTParameterAttributesFilterParams } from './functional-type-parameter-attributes.interface';

export class FTParameterAttributesFilterParams extends TablePageFilter<IFTParameterAttributesFilterParams> {
  functionalTypeParameterId: number;
  functionalTypeId: number;
  parameterId: number;

  constructor(filter?: IFTParameterAttributesFilterParams) {
    super(filter);
  }

  toObject(): IFTParameterAttributesFilterParams {
    return {
      ...super.toObjectBase(),
      functionalTypeParameterId: this.functionalTypeParameterId,
      functionalTypeId: this.functionalTypeId,
      parameterId: this.parameterId,
    };
  }
}
