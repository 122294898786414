<div class="error-container" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'error'">
    <div [ngStyle]="{ 'background-color': 'rgb(230, 89, 89)' }" class="error-line"></div>
  </ng-container>
  <ng-container *ngSwitchCase="'warning'">
    <div [ngStyle]="{ 'background-color': 'rgb(235, 190, 106)' }" class="error-line"></div>
  </ng-container>

  <app-expandable-block entityType="description" class="binding-error-wrap">
    <div class="error-label">
      <ng-container *ngSwitchCase="'error'">
        <svg-icon class="icon error" src="./assets/icons/invalid.svg"></svg-icon>
        <span>{{ 'ERRORS.ERROR_LABEL' | translate }}:</span>
      </ng-container>
      <ng-container *ngSwitchCase="'warning'">
        <svg-icon class="icon warning" src="./assets/icons/warning.svg"></svg-icon>
        <span>{{ 'ERRORS.WARNING' | translate }}:</span>
      </ng-container>
    </div>
    <span><ng-content></ng-content></span>
  </app-expandable-block>
</div>
