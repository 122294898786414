import { NavigationPage } from '@common/interfaces/Navigation';
import { NavigationCategories } from '@common/enums';

import { ROUTES_PARTS } from '../routing';
import { Permissions } from '@core/account/types';

export const NAVIGATION_ITEMS: NavigationPage[] = [
  /** Access */
  {
    name: 'USERS',
    slug: 'users',
    link: ROUTES_PARTS.USERS,
    type: NavigationCategories.Access,
  },
  {
    name: 'ROLES',
    slug: 'roles',
    link: ROUTES_PARTS.ROLES,
    type: NavigationCategories.Access,
  },
  {
    name: 'PROJECTS',
    slug: 'projects',
    link: ROUTES_PARTS.PROJECTS,
    type: NavigationCategories.Access,
  },
  {
    name: 'STANDARDS',
    slug: 'standards',
    link: ROUTES_PARTS.STANDARDS,
    type: NavigationCategories.Access,
    permission: Permissions.Standard,
  },
  /** Elements */
  {
    name: 'FUNCTIONAL_TYPES',
    slug: 'functionalTypes',
    link: ROUTES_PARTS.FUNCTIONAL_TYPES,
    type: NavigationCategories.Elements,
  },
  {
    name: 'FAMILY_TREE',
    slug: 'tree',
    link: ROUTES_PARTS.TREE,
    type: NavigationCategories.Elements,
  },
  {
    name: 'FAMILIES',
    slug: 'families',
    link: ROUTES_PARTS.FAMILIES,
    type: NavigationCategories.Elements,
  },
  {
    name: 'MATERIALS',
    slug: 'materials',
    link: ROUTES_PARTS.MATERIALS,
    type: NavigationCategories.Elements,
  },
  {
    name: 'NOMENCLATURE_TYPES',
    slug: 'nomenclature-types',
    link: ROUTES_PARTS.NOMENCLATURE_TYPES,
    type: NavigationCategories.Elements,
    permission: Permissions.VisibilityOfBindings,
  },

  /** Parameters */
  {
    name: 'BASE_PARAMETERS',
    slug: 'parameters',
    link: ROUTES_PARTS.PARAMETERS,
    type: NavigationCategories.Parameters,
    permission: Permissions.VisibilityOfParameters,
  },
  {
    name: 'UNIQUE_PARAMETERS',
    slug: 'uniqueParameters',
    link: ROUTES_PARTS.UNIQUE_PARAMETERS,
    type: NavigationCategories.Parameters,
    permission: Permissions.VisibilityOfParameters,
  },
  {
    name: 'FUNCTIONAL_TYPE_PARAMETERS',
    slug: 'functionalTypesParameters',
    link: ROUTES_PARTS.FUNCTIONAL_TYPES_PARAMETERS,
    type: NavigationCategories.Parameters,
    permission: Permissions.VisibilityOfParameters,
  },
  {
    name: 'MATERIAL_PARAMETERS',
    slug: 'materialsParameters',
    link: ROUTES_PARTS.MATERIALS_PARAMETERS,
    type: NavigationCategories.Parameters,
    permission: Permissions.VisibilityOfParameters,
  },
  {
    name: 'SPECIFICATIONS',
    slug: 'specifications',
    link: ROUTES_PARTS.SPECIFICATIONS,
    type: NavigationCategories.Parameters,
    permission: Permissions.VisibilityOfBindings,
  },

  /** Dictionaries */
  {
    name: 'DISCIPLINES',
    slug: 'disciplines',
    link: ROUTES_PARTS.DISCIPLINES,
    type: NavigationCategories.Dictionaries,
  },
  {
    name: 'ATTRIBUTES',
    slug: 'attributes',
    link: ROUTES_PARTS.ATTRIBUTES,
    type: NavigationCategories.Dictionaries,
  },
  {
    name: 'CATEGORIES',
    slug: 'categories',
    link: ROUTES_PARTS.CATEGORIES,
    type: NavigationCategories.Dictionaries,
  },
  /*  {
    name: 'DATA_TYPES',
    slug: 'data-types',
    link: ROUTES_PARTS.DATA_TYPES,
    type: NavigationCategories.Dictionaries,
  },*/
  {
    name: 'UNIT_TYPES',
    slug: 'unit-types',
    link: ROUTES_PARTS.UNIT_TYPES,
    type: NavigationCategories.Dictionaries,
  },

  /** Services */
  {
    name: 'REQUESTS',
    slug: 'requests',
    link: ROUTES_PARTS.REQUESTS,
    type: NavigationCategories.Services,
  },
  {
    name: 'BINDINGS',
    slug: 'bindings',
    link: ROUTES_PARTS.BINDINGS,
    type: NavigationCategories.Services,
    permission: Permissions.VisibilityOfBindings,
  },
  {
    name: 'BINDINGS_CHECK',
    slug: 'bindings-check',
    link: ROUTES_PARTS.BINDINGS_CHECK,
    type: NavigationCategories.Services,
    permission: Permissions.VisibilityOfBindings,
  },
  {
    name: 'SELECTION_OF_NOMENCLATURES',
    slug: 'elements-check',
    link: ROUTES_PARTS.SELECTION_OF_NOMENCLATURES,
    type: NavigationCategories.Services,
    permission: Permissions.VisibilityOfBindings,
  },
  {
    name: 'STATISTIC',
    slug: 'dashboard',
    link: ROUTES_PARTS.DASHBOARD,
    type: NavigationCategories.Services,
  },
  {
    name: 'LOAD_LOG',
    slug: 'loadlogs',
    link: ROUTES_PARTS.LOAD_LOGS,
    type: NavigationCategories.Services,
  },
];
