export enum HistoryType {
  uniqueParam = 1,
  family,
  funcionalType,
}

export enum HistoryActions {
  add = 1,
  edit,
  delete,
  archive,
  restore,
}
