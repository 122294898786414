import { AfterViewInit, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { PikDrawerComponent } from '@pik-ui/ng-components';

import { ViewDrawerData } from './interfaces';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { PermissionService } from '@core/account/services';
import { Subject, takeUntil, tap } from 'rxjs';
import { RowService } from '@services/row.service';
import { FullVersionParameterDto } from '@common/dto';

@Component({
  selector: 'app-view-drawer',
  templateUrl: './view-drawer.component.html',
  styleUrls: ['./view-drawer.component.scss'],
})
export class ViewDrawerComponent implements AfterViewInit {
  @ViewChild('drawer', { static: false }) drawer: PikDrawerComponent;
  @ViewChild('roleType', { static: false }) roleType: PikDrawerComponent;
  @ViewChild('disciplines', { static: false }) disciplines: TemplateRef<any>;
  @ViewChild('standards', { static: false }) standards: TemplateRef<any>;
  @ViewChild('roles', { static: false }) roles: TemplateRef<any>;
  @ViewChild('family', { static: false }) family: TemplateRef<any>;
  @ViewChild('symbols', { static: false }) symbols: TemplateRef<any>;
  @ViewChild('functionalType', { static: false }) functionalType: TemplateRef<any>;
  @ViewChild('functionalTypes', { static: false }) functionalTypes: TemplateRef<any>;
  @ViewChild('requests', { static: false }) requests: TemplateRef<any>;
  @ViewChild('bindings', { static: false }) bindings: TemplateRef<any>;
  @ViewChild('status', { static: false }) status: TemplateRef<any>;
  @ViewChild('appType', { static: false }) appType: TemplateRef<any>;
  @ViewChild('familyType', { static: false }) familyType: TemplateRef<any>;
  @ViewChild('category', { static: false }) category: TemplateRef<any>;
  @ViewChild('entity', { static: false }) entity: TemplateRef<any>;
  @ViewChild('families', { static: false }) families: TemplateRef<any>;
  @ViewChild('code', { static: false }) code: TemplateRef<any>;
  @ViewChild('hasFile', { static: false }) hasFile: TemplateRef<any>;
  @ViewChild('actualVersion', { static: false }) actualVersion: TemplateRef<any>;
  @ViewChild('version', { static: false }) version: TemplateRef<any>;
  @ViewChild('address', { static: false }) address: TemplateRef<any>;
  @ViewChild('parameterValues', { static: false }) parameterValues: TemplateRef<any>;
  @ViewChild('parameterValue', { static: false }) parameterValue: TemplateRef<any>;
  @ViewChild('parameterValuesDescription', { static: false }) parameterValuesDescription: TemplateRef<any>;
  @ViewChild('dataType', { static: false }) dataType: TemplateRef<any>;
  @ViewChild('unitType', { static: false }) unitType: TemplateRef<any>;
  @ViewChild('uniqueParameter', { static: false }) uniqueParameter: TemplateRef<any>;
  @ViewChild('isRequiredForRequest', { static: false }) isRequiredForRequest: TemplateRef<any>;
  @ViewChild('isType', { static: false }) isType: TemplateRef<any>;
  @ViewChild('attributeType', { static: false }) attributeType: TemplateRef<any>;
  @ViewChild('attribute', { static: false }) attribute: TemplateRef<any>;
  @ViewChild('permissions', { static: false }) permissions: TemplateRef<any>;
  @ViewChild('decimals', { static: false }) decimals: TemplateRef<any>;
  @ViewChild('isRequired', { static: false }) isRequired: TemplateRef<any>;
  @ViewChild('isAddress', { static: false }) isAddress: TemplateRef<any>;
  @ViewChild('sortNumber', { static: false }) sortNumber: TemplateRef<any>;
  @ViewChild('material', { static: false }) material: TemplateRef<any>;
  @ViewChild('history', { static: false }) history: TemplateRef<any>;

  @Output() edit = new EventEmitter<number>();

  ROUTES_PARTS = ROUTES_PARTS;
  location = window.location.origin + '/';
  generalTags: TemplateRef<any>[] = [];
  data: ViewDrawerData = {};
  ThemeColors = ThemeColors;
  canEdit = false;
  public destroy$ = new Subject();

  constructor(public permissionService: PermissionService, public rowService: RowService) {}

  ngAfterViewInit() {
    this.drawer.openedChange
      .pipe(
        tap((opened: boolean) => {
          if (!opened) {
            this.rowService.setUpdateState(null);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  setOrder() {
    this.generalTags = [];
    Object.keys(this.data.generalTags ?? []).forEach((item) => this.generalTags.push(this[item]));
  }

  open(data: ViewDrawerData, editable?: boolean, info?: FullVersionParameterDto) {
    this.data = data;
    if (editable === false) {
      this.canEdit = false;
    } else {
      this.canEdit = this.getEditCondition(data);
    }
    this.setOrder();
    this.drawer.open();
    const id = data.entity?.id ? data.entity?.id : info?.functionalTypeParameterId;
    this.rowService.setUpdateState(id);
  }

  onEdit() {
    this.drawer.close();
    this.edit.emit(this.data.entity.id);
  }

  private getEditCondition(data: ViewDrawerData): boolean {
    return (
      !!this.permissionService.access[data.editPermission] &&
      (data.editCondition !== undefined ? data.editCondition : true)
    );
  }
}
