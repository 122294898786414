import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss'],
})
export class ExpandableSectionComponent {
  @Input() morePhrase = 'COMMON_LABELS.MORE_PARAMETERS';
  @Input() lessPhrase = 'COMMON_LABELS.LESS_PARAMETERS';

  isShowMore: boolean;
}
