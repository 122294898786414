import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';

import { TableBuilderComponent } from '@shared/modules/table-builder/table-builder/table-builder.component';
import { TablePageServiceBase } from '@shared/modules/table-builder';

@Component({
  selector: 'app-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
})
export class PageBuilderComponent implements AfterContentInit {
  @Input() pageTitle: string;
  @Input() hint: string;

  @ContentChild(TableBuilderComponent)
  tableComponent: TableBuilderComponent;

  constructor(public pageService: TablePageServiceBase, public _route: ActivatedRoute) {
    if (pageService.filter) {
      const { params } = this._route.snapshot;

      if (!isEmpty(params)) {
        this.pageService.resetFilter();
      }

      const _params = Object.keys(params).reduce((obj, key) => {
        obj[key] = JSON.parse(params[key]);

        return obj;
      }, {});

      this.pageService.filter.setFilter(_params);
    }
  }

  ngAfterContentInit() {
    if (this.tableComponent) {
      this.tableComponent.isPageTable = true;
      this.tableComponent.pageService.loadAll();
    }
  }
}
