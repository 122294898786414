<pik-drawer #drawer maxWidth="950px">
  <pik-drawer-header>
    <h2 class="drawer-title">
      <div class="drawer-title__name">
        <svg-icon
          *ngIf="data.warningMessage"
          class="warning"
          src="./assets/icons/invalid.svg"
          [pikTooltip]="data.warningMessage"
        ></svg-icon>

        {{ data?.entity?.name || ('COMMON.VIEW' | translate) }}
        <ng-container *ngIf="data.fullName"> - {{ data.fullName }} </ng-container>
        <ng-container *ngIf="data?.entity?.id">
          <div class="title-id">
            <span>ID {{ data.entity.id }}</span>
            <fm-copy-button *ngIf="data?.link" type="link" [text]="location + data.link"></fm-copy-button>
          </div>
        </ng-container>
      </div>

      <ng-container *hasDisciplines="data.disciplineAccess ? data.generalTags.disciplines.data : []">
        <div class="drawer-title__edit" *ngIf="canEdit">
          <button pikButton appearance="inverse" class="material-button" (click)="onEdit()">
            <svg-icon src="./assets/icons/edit-2.svg" pikButtonIcon position="before"></svg-icon>
            {{ 'COMMON.EDIT' | translate }}
          </button>
        </div>
      </ng-container>
    </h2>
  </pik-drawer-header>

  <pik-drawer-content>
    <ng-container *ngIf="data.error">
      <h3>Ошибка:</h3>
      <div>
        {{ data.error }}
      </div>
    </ng-container>
    <div class="general-tags-wrap" [ngClass]="{ withImage: !!data.image }">
      <ng-container *ngIf="data.image">
        <app-image-loader [isChangeAllowed]="false" mode="view" [image]="data.image"></app-image-loader>
      </ng-container>
      <div class="drawer-tags">
        <ng-container *ngFor="let field of generalTags">
          <ng-container [ngTemplateOutlet]="field"></ng-container>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="data.simpleFields as fields">
      <div class="drawer-tags">
        <ng-container *ngFor="let field of fields">
          <tags-item title="{{ field.name | translate }}" [tooltip]="field.tooltip">
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="'boolean'"
                ><app-check-icon [value]="field.value"></app-check-icon
              ></ng-container>
              <ng-container *ngSwitchCase="'list'">
                <app-list-tags [data]="field.value"></app-list-tags>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <app-check-empty [value]="field.value"></app-check-empty>
              </ng-container>
            </ng-container>
          </tags-item>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="data?.creation || data?.lastEdition">
      <div class="drawer-tags">
        <tags-item title="{{ 'COMMON_LABELS.AUTHOR' | translate }}" *ngIf="data.creation.author">
          <app-user-link [user]="data.creation.author"></app-user-link>
        </tags-item>
        <tags-item title="{{ 'COMMON_LABELS.CREATED_DATE' | translate }}" *ngIf="data.creation.date">
          <span>{{ data.creation.date | dateFormat: true }}</span>
        </tags-item>

        <tags-item title="{{ 'COMMON_LABELS.EDITOR' | translate }}" *ngIf="data.lastEdition?.lastEditor">
          <app-user-link [user]="data.lastEdition.lastEditor"></app-user-link>
        </tags-item>
        <tags-item
          title="{{ 'COMMON_LABELS.EDITED_DATE' | translate }}"
          *ngIf="data.lastEdition?.date && data.lastEdition?.lastEditor"
        >
          <span>{{ data.lastEdition.date | dateFormat: true }}</span>
        </tags-item>
      </div>
    </ng-container>

    <ng-container *ngIf="data.counters">
      <div class="drawer-tags">
        <tags-item title="{{ 'COMMON_LABELS.FAMILIES' | translate }}" *ngIf="data.counters?.families as families">
          <app-families-link [params]="families.filter">{{ families.count }}</app-families-link>
        </tags-item>

        <tags-item
          title="{{ 'COMMON_LABELS.FUNCTIONAL_TYPES' | translate }}"
          *ngIf="data.counters?.functionalTypes as functionalTypes"
        >
          <app-functional-types-link [params]="functionalTypes.filter">{{
            functionalTypes.count
          }}</app-functional-types-link>
        </tags-item>

        <tags-item title="{{ 'COMMON_LABELS.MATERIALS' | translate }}" *ngIf="data.counters?.materials as materials">
          <app-materials-link [params]="materials.filter">{{ materials.count }}</app-materials-link>
        </tags-item>

        <ng-container *ngIf="data.counters?.requests as requests">
          <tags-item title="{{ 'COMMON_LABELS.OPENED_REQUESTS' | translate }}">
            <app-request-link [params]="requests.openedRequestsFilter">{{ requests.openedRequests }}</app-request-link>
          </tags-item>

          <tags-item title="{{ 'COMMON_LABELS.REQUESTS' | translate }}">
            <app-request-link [params]="requests.allRequestsFilter">{{ requests.allRequests }}</app-request-link>
          </tags-item>
        </ng-container>

        <ng-container *ngIf="data.counters?.bindings as bindings">
          <tags-item title="{{ 'COMMON_LABELS.INTERNAL_BINDINGS' | translate }}" *ngIf="bindings.internalBindings">
            <app-bindings-link [params]="bindings.internalFilter">{{ bindings.internalBindings }}</app-bindings-link>
          </tags-item>

          <tags-item title="{{ 'COMMON_LABELS.EXTERNAL_BINDINGS' | translate }}" *ngIf="bindings.snapBindings">
            <app-bindings-link [params]="bindings.snapFilter">{{ bindings.snapBindings }}</app-bindings-link>
          </tags-item>
        </ng-container>

        <tags-item title="{{ 'COMMON_LABELS.DOWNLOADS' | translate }}" *ngIf="data.counters?.downloads as downloads">
          <app-load-log-link [params]="downloads.filter">{{ downloads.count }}</app-load-log-link>
        </tags-item>

        <tags-item title="{{ 'COMMON_LABELS.ATTRIBUTES' | translate }}" *ngIf="data.counters?.attributes as attributes">
          <app-attributes-link [params]="attributes.filter">{{ attributes.count }}</app-attributes-link>
        </tags-item>

        <tags-item
          title="{{ 'COMMON_LABELS.MATERIAL_PARAMETERS' | translate }}"
          *ngIf="data.counters?.materialParameters as materialParameters"
        >
          <app-material-parameters-link [params]="materialParameters.filter">{{
            materialParameters.count
          }}</app-material-parameters-link>
        </tags-item>

        <tags-item title="{{ 'COMMON_LABELS.PARAMETERS' | translate }}" *ngIf="data.counters?.parameters as parameters">
          <app-parameters-link [params]="parameters.filter">{{ parameters.count }}</app-parameters-link>
        </tags-item>
      </div>
    </ng-container>

    <ng-container *ngIf="data?.attributes?.data.length">
      <div class="drawer-tags">
        <tags-item title="{{ 'COMMON_LABELS.ATTRIBUTES' | translate }}" [tooltip]="data.attributes.tooltip">
          <app-expandable-block entityType="attributes">
            <app-attributes-view [attributes]="data.attributes.data"></app-attributes-view>
          </app-expandable-block>
        </tags-item>
      </div>
    </ng-container>

    <ng-container *ngIf="data?.description">
      <div class="drawer-tags">
        <tags-item title="{{ 'COMMON_LABELS.DESCRIPTION' | translate }}" [tooltip]="data?.description.tooltip">
          <app-expandable-block entityType="description">
            <markdown-viewer [data]="data?.description.data"></markdown-viewer>
          </app-expandable-block>
        </tags-item>
      </div>
    </ng-container>

    <ng-container *ngIf="data?.history">
      <tags-item title="{{ 'HISTORY_MODULE.TITLE' | translate }}">
        <app-history [data]="data?.history"></app-history>
      </tags-item>
    </ng-container>

    <ng-container *ngIf="data?.versionDescription">
      <div class="drawer-tags">
        <tags-item title="{{ 'COMMON_LABELS.VERSION_DESCRIPTION' | translate }}">
          <app-expandable-block entityType="description">
            <markdown-viewer [data]="data?.versionDescription"></markdown-viewer>
          </app-expandable-block>
        </tags-item>
      </div>
    </ng-container>
  </pik-drawer-content>

  <pik-drawer-footer>
    <pik-button-group>
      <button pikButton (click)="drawer.close()">
        {{ 'COMMON.CLOSE' | translate }}
      </button>
    </pik-button-group>
  </pik-drawer-footer>
</pik-drawer>

<ng-template #disciplines>
  <tags-item title="{{ 'COMMON_LABELS.DISCIPLINES' | translate }}" [tooltip]="data.generalTags.disciplines.tooltip">
    <app-disciplines-tags [disciplines]="data.generalTags.disciplines.data"></app-disciplines-tags>
  </tags-item>
</ng-template>

<ng-template #standards>
  <tags-item title="{{ 'COMMON_LABELS.STANDARDS' | translate }}">
    <app-standard-tags [standards]="data.generalTags.standards"></app-standard-tags>
  </tags-item>
</ng-template>

<ng-template #roles>
  <tags-item title="{{ 'COMMON_LABELS.ROLES' | translate }}">
    <app-roles-tags [roles]="data.generalTags.roles"></app-roles-tags>
  </tags-item>
</ng-template>

<ng-template #functionalType>
  <tags-item title="{{ 'COMMON_LABELS.FUNCTIONAL_TYPE' | translate }}">
    <app-functional-type-tag [entity]="data.generalTags.functionalType"></app-functional-type-tag>
  </tags-item>
</ng-template>

<ng-template #functionalTypes>
  <tags-item
    title="{{ 'COMMON_LABELS.FUNCTIONAL_TYPES' | translate }}"
    [tooltip]="data.generalTags.functionalTypes.tooltip"
  >
    <app-functional-type-tag
      [multi]="true"
      [functionalTypes]="data.generalTags.functionalTypes.data"
    ></app-functional-type-tag>
  </tags-item>
</ng-template>

<ng-template #family>
  <tags-item title="{{ 'COMMON_LABELS.FAMILY' | translate }}" [tooltip]="data.generalTags.family.tooltip">
    <app-family-tag [entity]="data.generalTags.family.data" mode="single"></app-family-tag>
  </tags-item>
</ng-template>

<ng-template #symbols>
  <tags-item title="{{ 'COMMON_LABELS.SYMBOLS' | translate }}">
    <app-family-symbol-tag
      [multi]="true"
      [family]="data.generalTags.family.data"
      [version]="data.generalTags.version.versionNumber"
      [symbols]="data.generalTags.symbols"
    >
    </app-family-symbol-tag>
  </tags-item>
</ng-template>

<ng-template #material>
  <tags-item title="{{ 'COMMON_LABELS.MATERIAL' | translate }}">
    <app-material-tag [entity]="data.generalTags.material"></app-material-tag>
  </tags-item>
</ng-template>

<ng-template #requests>
  <tags-item title="{{ 'COMMON_LABELS.REQUESTS' | translate }}">
    <app-request-tag
      mode="entityCard"
      [all]="data.generalTags.requests.requestsCount"
      [opened]="data.generalTags.requests.openedRequestsCount"
      [filter]="data.generalTags.requests.filter"
    ></app-request-tag>
  </tags-item>
</ng-template>

<ng-template #bindings>
  <tags-item title="{{ 'COMMON_LABELS.BINDINGS' | translate }}" *ngIf="permissionService.access.VisibilityOfBindings">
    <app-binding-tag
      [entityId]="data.generalTags.bindings.entityId"
      [bindingType]="data.generalTags.bindings.bindingType"
      [internalCount]="data.generalTags.bindings.internalCount"
      [snapCount]="data.generalTags.bindings.snapCount"
    ></app-binding-tag>
  </tags-item>
</ng-template>

<ng-template #status>
  <tags-item title="{{ 'COMMON_LABELS.STATUS' | translate }}" [tooltip]="data.generalTags.status.tooltip">
    <fm-status-tag [statusId]="data.generalTags.status.data"></fm-status-tag>
  </tags-item>
</ng-template>

<ng-template #appType>
  <tags-item title="{{ 'COMMON_LABELS.APPLICATION' | translate }}">
    <fm-app-tag [appType]="data.generalTags.appType"></fm-app-tag>
  </tags-item>
</ng-template>

<ng-template #familyType>
  <tags-item title="{{ 'COMMON_LABELS.TYPE' | translate }}">
    <app-family-type-tag [type]="data.generalTags.familyType"></app-family-type-tag>
  </tags-item>
</ng-template>

<ng-template #category>
  <tags-item title="{{ 'COMMON_LABELS.CATEGORY' | translate }}" [tooltip]="data.generalTags.category.tooltip">
    <app-category-tag [category]="data.generalTags.category.data"></app-category-tag>
  </tags-item>
</ng-template>

<ng-template #code>
  <tags-item title="{{ 'COMMON_LABELS.CODE' | translate }}" [tooltip]="data.generalTags.code.tooltip">
    {{ data.generalTags.code.data }}
  </tags-item>
</ng-template>

<ng-template #families>
  <tags-item title="{{ 'COMMON_LABELS.FAMILIES' | translate }}">
    <app-families-tag
      [count]="data.generalTags.families.count"
      [filter]="data.generalTags.families.filter"
    ></app-families-tag>
  </tags-item>
</ng-template>

<ng-template #hasFile>
  <tags-item title="{{ 'COMMON_LABELS.FILE' | translate }}" [tooltip]="data.generalTags.hasFile.tooltip">
    <pik-tag [color]="ThemeColors.Gray5" [textColor]="ThemeColors.DarkBlue">{{
      (data.generalTags.hasFile.data ? 'MATERIALS_MODULE.OTHER.HAS_FILE' : 'MATERIALS_MODULE.OTHER.HAS_NO_FILE')
        | translate
    }}</pik-tag>
  </tags-item>
</ng-template>

<ng-template #actualVersion>
  <tags-item
    title="{{ 'COMMON_LABELS.ACTUAL_VERSION' | translate }}"
    [tooltip]="data.generalTags.actualVersion.tooltip"
  >
    {{ data.generalTags.actualVersion.data }}
  </tags-item>
</ng-template>

<ng-template #version>
  <tags-item title="{{ 'COMMON_LABELS.VERSION' | translate }}">
    <a [routerLink]="data.generalTags.version.link">{{ data.generalTags.version.versionNumber }}</a>
  </tags-item>
</ng-template>

<ng-template #parameterValues>
  <tags-item title="{{ 'COMMON_LABELS.VALUES' | translate }}" [tooltip]="data.generalTags.parameterValues.tooltip">
    <app-parameters-view
      [dataType]="data.generalTags?.dataType?.code"
      [value]="data.generalTags.parameterValues.data"
    ></app-parameters-view>
  </tags-item>
</ng-template>

<ng-template #parameterValue>
  <tags-item title="{{ 'COMMON_LABELS.VALUE' | translate }}" [tooltip]="data.generalTags.parameterValue.tooltip">
    <app-parameters-view
      [dataType]="data.generalTags?.dataType?.code"
      [value]="data.generalTags.parameterValue.data"
    ></app-parameters-view>
  </tags-item>
</ng-template>

<ng-template #parameterValuesDescription>
  <tags-item title="{{ 'COMMON_LABELS.VALID_VALUES' | translate }}">
    <app-parameters-view
      [dataType]="data.generalTags?.dataType?.code"
      [value]="data.generalTags.parameterValuesDescription"
    ></app-parameters-view>
  </tags-item>
</ng-template>

<ng-template #dataType>
  <tags-item title="{{ 'COMMON_LABELS.DATA_TYPE' | translate }}">
    <app-data-type-link [entity]="data.generalTags.dataType"></app-data-type-link>
  </tags-item>
</ng-template>

<ng-template #unitType>
  <tags-item title="{{ 'COMMON_LABELS.UNIT_TYPE' | translate }}">
    <app-unit-type-link [entity]="data.generalTags.unitType"></app-unit-type-link>
  </tags-item>
</ng-template>

<ng-template #sortNumber>
  <tags-item title="{{ 'COMMON_LABELS.SORT_NUMBER' | translate }}" [tooltip]="data.generalTags.sortNumber.tooltip">
    {{ data.generalTags.sortNumber.data }}
  </tags-item>
</ng-template>

<ng-template #uniqueParameter>
  <tags-item
    title="{{ 'COMMON_LABELS.UNIQUE_PARAMETER' | translate }}"
    *ngIf="data.generalTags.uniqueParameter as uniqueParameter"
    [tooltip]="data.generalTags.uniqueParameter.tooltip"
  >
    <app-unique-parameters-link [params]="uniqueParameter.filter">
      {{ uniqueParameter.name }}
    </app-unique-parameters-link>
  </tags-item>
</ng-template>

<ng-template #address>
  <tags-item title="{{ 'COMMON_LABELS.ADDRESS' | translate }}" [tooltip]="data.generalTags.address.tooltip">
    <app-check-icon [value]="data.generalTags.address.data"></app-check-icon>
  </tags-item>
</ng-template>

<ng-template #isRequired>
  <tags-item title="{{ 'COMMON_LABELS.REQUIRED' | translate }}" [tooltip]="data.generalTags.isRequired.tooltip">
    <app-check-icon [value]="data.generalTags.isRequired.data"></app-check-icon>
  </tags-item>
</ng-template>

<ng-template #isAddress>
  <tags-item title="{{ 'COMMON_LABELS.ADDRESS' | translate }}" [tooltip]="data.generalTags.isAddress.tooltip">
    <app-check-icon [value]="data.generalTags.isAddress.data"></app-check-icon>
  </tags-item>
</ng-template>

<ng-template #isRequiredForRequest>
  <tags-item
    title="{{ 'COMMON_LABELS.IN_REQUESTS' | translate }}"
    [tooltip]="data.generalTags.isRequiredForRequest.tooltip"
  >
    <app-check-icon [value]="data.generalTags.isRequiredForRequest.data"></app-check-icon>
  </tags-item>
</ng-template>

<ng-template #isType>
  <tags-item title="{{ 'COMMON_LABELS.TYPE_PARAMETER' | translate }}">
    <app-check-icon [value]="data.generalTags.isType"></app-check-icon>
  </tags-item>
</ng-template>

<ng-template #attributeType>
  <tags-item title="{{ 'COMMON_LABELS.DATA_TYPE' | translate }}" [tooltip]="data.generalTags.attributeType.tooltip">
    <app-attribute-type-tag [attributeType]="data.generalTags.attributeType.data"></app-attribute-type-tag>
  </tags-item>
</ng-template>

<ng-template #attribute>
  <tags-item title="{{ 'COMMON_LABELS.ATTRIBUTE' | translate }}" [tooltip]="data.generalTags.attribute.tooltip">
    <app-attributes-view [attributes]="data.generalTags.attribute.data"></app-attributes-view>
  </tags-item>
</ng-template>

<ng-template #permissions>
  <tags-item
    title="{{ 'COMMON_LABELS.PERMISSIONS' | translate }}"
    helpLink="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/about/permissions"
  >
    <app-permission-tags [permissions]="data.generalTags.permissions"></app-permission-tags>
  </tags-item>
</ng-template>

<ng-template #roleType>
  <tags-item title="{{ 'COMMON_LABELS.TYPE' | translate }}" [tooltip]="data.generalTags.roleType.tooltip">
    <app-role-type-tag [type]="data.generalTags.roleType.data"></app-role-type-tag>
  </tags-item>
</ng-template>

<ng-template #decimals>
  <tags-item title="{{ 'COMMON_LABELS.DECIMALS' | translate }}" [tooltip]="data.generalTags.decimals.tooltip">
    <ng-container *ngIf="data.generalTags.decimals !== null; else negativeIcon">
      {{ data.generalTags.decimals.data }}
    </ng-container>
  </tags-item>
</ng-template>

<ng-template #negativeIcon>—</ng-template>
