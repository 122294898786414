<button class="more-button" pikButton appearance="link" *ngIf="!isShowMore" (click)="isShowMore = true">
  <svg-icon pikButtonIcon src="./assets/icons/plus.svg"></svg-icon>
  {{ morePhrase | translate }}
</button>

<ng-container *ngIf="isShowMore">
  <div>
    <ng-content></ng-content>
  </div>
</ng-container>

<button class="more-button" pikButton appearance="link" *ngIf="isShowMore" (click)="isShowMore = false">
  <svg-icon pikButtonIcon src="./assets/icons/minus.svg"></svg-icon>
  {{ lessPhrase | translate }}
</button>
