import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CrudService, FunctionalTypeAttributeValue } from '@common/interfaces';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class FunctionalTypeAttributeValuesApiService implements CrudService<FunctionalTypeAttributeValue> {
  constructor(private http: HttpClient) {}

  public getById(entityId: number): Observable<FunctionalTypeAttributeValue> {
    return this.http.get<FunctionalTypeAttributeValue>(`${ApiPaths.FunctionalTypeAttributeValues}/${entityId}`);
  }

  public create(entity: FunctionalTypeAttributeValue): Observable<FunctionalTypeAttributeValue> {
    return this.http.post<FunctionalTypeAttributeValue>(ApiPaths.FunctionalTypeAttributeValues, entity);
  }

  public update(entity: FunctionalTypeAttributeValue) {
    return this.http.put<FunctionalTypeAttributeValue>(
      `${ApiPaths.FunctionalTypeAttributeValues}/${entity.id}`,
      entity,
    );
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.FunctionalTypeAttributeValues}/${entityId}`);
  }
}
