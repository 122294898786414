export * from './disciplines-tags/disciplines-tags.component';
export * from './discipline-tag/discipline-tag.component';
export * from './binding-tag/binding-tag.component';
export * from './family-tag/family-tag.component';
export * from './material-count-tag/material-count-tag.component';
export * from './family-type-tag/family-type-tag.component';
export * from './functional-type-tag/functional-type-tag.component';
export * from './request-tag/request-tag.component';
export * from './material-tag/material-tag.component';
export * from './family-symbol-tag/family-symbol-tag.component';
export * from './attribute-type-tag/attribute-type-tag.component';
export * from './nomenclature-type-tag/nomenclature-type-tag.component';
export * from './projects-tag';
export * from './roles-tags';
export * from './standard-tags';
export * from './category-tag';
export * from './families-tag/families-tag.component';
export * from './role-type-tag/role-type-tag.component';
export * from './permission-tags/permission-tags.component';
