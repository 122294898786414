import { TablePageFilter } from '../table-page-filter-base';
import { INomenclatureParameterValuesFilterParams } from './nomenclature-parameter-values.interface';

export class NomenclatureParameterValuesFilterParams extends TablePageFilter<INomenclatureParameterValuesFilterParams> {
  id: number;
  matchingPropertyId: number;
  parameterValue: string;
  specificationValue: string;

  constructor(filter?: INomenclatureParameterValuesFilterParams) {
    super(filter);
  }

  toObject(): INomenclatureParameterValuesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      matchingPropertyId: this.matchingPropertyId,
      parameterValue: this.parameterValue,
      specificationValue: this.specificationValue,
    };
  }
}
