import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { UserDto } from '@common/dto';
import { ResultPart } from '@common/interfaces';
import { MentionOptions } from './mention-options';
import { MentionDropdown } from './mention-dropdown';
import { UsersFilterParams } from '@common/models/filters';

export class MentionService {
  options: MentionOptions;
  users: UserDto[];
  mentionListChange: Subject<UserDto[]>;

  private _mentionList: UserDto[];
  private _dropdown: MentionDropdown;
  private _searchHandler: Subject<string>;
  private _selectionOptionsSubs: Subscription;
  private _searchSubs: Subscription;

  constructor(private _componentRef: any, options?: MentionOptions) {
    this.options = new MentionOptions(options);

    this._dropdown = new MentionDropdown(
      _componentRef._editor,
      _componentRef._overlay,
      _componentRef._viewContainerRef,
      _componentRef._dropdownTemplateRef,
    );

    this.users = [];
    this._mentionList = [];
    this.mentionListChange = new Subject();
    this._searchHandler = new Subject();

    this._selectionOptionsSubs = this._dropdown.onSelectOption.subscribe((userId) => {
      const user = this.users.find((_user) => _user.id === userId);

      if (user) {
        this.attachUser(user);
      }
    });

    this._searchSubs = this._searchHandler
      .pipe(
        debounceTime(150),
        switchMap((searchTerm: string): Observable<UserDto[]> => {
          return this._componentRef._userService
            .getUserList(new UsersFilterParams({ searchString: searchTerm }))
            .pipe(
              map(({ result }: ResultPart<UserDto>) =>
                result.map((user) => ({ ...user, name: user.name + ' (' + user.login + ')' })),
              ),
            );
        }),
      )
      .subscribe((users: UserDto[]) => {
        this.users = users;
        this._dropdown.open();
      });
  }

  async showSuggestionList(searchTerm: string = '') {
    if (searchTerm.length >= this.options.minSearchLength) {
      this._searchHandler.next(searchTerm);
    } else {
      this._dropdown.close();
    }
  }

  attachUser(user: UserDto) {
    // close opened dropdown
    this._dropdown.close();

    // insert mention to editor
    this._insertMention(user);

    // add user to mention list if hadn't add
    const index = this._mentionList.indexOf(user);

    if (index === -1) {
      this._mentionList.push(user);
      this.mentionListChange.next(this._mentionList);
    }
  }

  destroy() {
    this.mentionListChange.complete();
    this._searchHandler.complete();
    this._searchSubs.unsubscribe();
    this._selectionOptionsSubs.unsubscribe();
    this._dropdown.destroy();
  }

  onEditorTextChange(htmlString: string) {
    const shadowEl = document.createElement('div');
    shadowEl.innerHTML = htmlString;
    const mentions = shadowEl.querySelectorAll('span.mention');
    const realMentionIds = Array.from(mentions).map((el: HTMLElement) => parseInt(el.dataset.id, 0));
    this._mentionList = this._mentionList.filter((user) => realMentionIds.includes(user.id));
    this.mentionListChange.next(this._mentionList);
  }

  private _insertMention(user: UserDto) {
    const mention = this._componentRef._editor.getModule('mention');

    mention.insertItem({
      denotationChar: '@',
      id: user.id,
      value: user.name,
    });
  }
}
