import { TablePageFilter } from '../table-page-filter-base';
import { INomenclaturesFilterParams } from '../index';

export class NomenclaturesFilterParams extends TablePageFilter<INomenclaturesFilterParams> {
  id: number;
  name: string;
  showDeleted: boolean;
  dataTypeIds: number[];
  searchString: string;

  constructor(filter?: INomenclaturesFilterParams) {
    super(filter);
  }

  toObject(): INomenclaturesFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      showDeleted: this.showDeleted,
      dataTypeIds: this.dataTypeIds,
      searchString: this.searchString,
    };
  }
}
