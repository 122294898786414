<fm-page>
  <fm-page-header>
    <div class="fmFilter">
      <div class="fmFilter__col">
        <h2>{{ pageTitle | translate }}</h2>
        <ng-container *ngIf="hint">
          <a [href]="hint" target="_blank" class="hint-button">
            <svg-icon class="fmLogo" src="./assets/icons/question.svg" [stretch]="true"></svg-icon>
          </a>
        </ng-container>
      </div>

      <div class="fmFilter__col">
        <ng-content select="'fm-page-top-toolbar'"></ng-content>
      </div>
    </div>
  </fm-page-header>

  <ng-content></ng-content>
</fm-page>
