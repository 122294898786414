<ng-container *ngIf="dataSource?.length">
  <perfect-scrollbar (psXReachEnd)="hideScrollingOverlay = true" (psScrollX)="hideScrollingOverlay = false">
    <table
      cdk-table
      class="pik-table pik-table--cards"
      [class.hide-scrolling-overlay]="hideScrollingOverlay"
      [dataSource]="dataSource"
      [trackBy]="trackBy || trackByIndex"
      pikSortHeader
      (sortChange)="sortChange.emit($event)"
      multiTemplateDataRows
      [active]="sortBy"
      [direction]="sortOrder === SortOrder.Ascending ? 'asc' : 'desc'"
      [disableClear]="true"
    >
      <ng-container
        *ngFor="let col of columns"
        [cdkColumnDef]="col.name"
        [sticky]="col.sticky"
        [stickyEnd]="col.stickyEnd"
      >
        <ng-template #headCellTemplate>
          <pik-ellipsis-text>{{ col.label | translate }}</pik-ellipsis-text>
        </ng-template>

        <ng-container *ngIf="col.sorted">
          <th cdk-header-cell [pik-sort-header]="col?.sortField ? col.sortField : col.name" *cdkHeaderCellDef>
            <div class="head-wrapper">
              <ng-container
                *ngTemplateOutlet="
                  headerTemplate ? headerTemplate.template : headCellTemplate;
                  context: { $implicit: col.label, col: col, defaultTemplate: headCellTemplate }
                "
              ></ng-container>
              <fm-info-tooltip *ngIf="col.description" [text]="tooltip"></fm-info-tooltip>
              <ng-template #tooltip>
                <span [innerHTML]="col.description | translate"></span>
              </ng-template>
            </div>
          </th>
        </ng-container>

        <ng-container *ngIf="!col.sorted">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div class="head-wrapper">
              <ng-container
                *ngTemplateOutlet="
                  headerTemplate ? headerTemplate.template : headCellTemplate;
                  context: { $implicit: col.label, col: col, defaultTemplate: headCellTemplate }
                "
              ></ng-container>
              <fm-info-tooltip *ngIf="col.description" [text]="tooltip"></fm-info-tooltip>
              <ng-template #tooltip>
                <span [innerHTML]="col.description | translate"></span>
              </ng-template>
            </div>
          </th>
        </ng-container>

        <td (click)="clickedCell = col" cdk-cell *cdkCellDef="let row">
          <a
            *ngIf="col.name !== 'tools' && areRowLinks; else commonCell"
            class="table-link"
            [href]="rowService.link"
            (click)="onCellLinkClick($event, col)"
          >
            <ng-container
              *ngTemplateOutlet="
                cellTemplate ? cellTemplate.template : colTemplate;
                context: { $implicit: row, col: col, defaultTemplate: colTemplate }
              "
            ></ng-container>

            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="ColumnType.ATTRIBUTES">
                <app-table-attributes [attributeName]="col.label" [data]="row"></app-table-attributes>
              </ng-container>
            </ng-container>

            <ng-template #colTemplate>
              <pik-ellipsis-text>{{ row[col.name] }}</pik-ellipsis-text>
            </ng-template>
          </a>
          <ng-template #commonCell>
            <div (mousedown)="onCellLinkClick($event, col)" class="123">
              <ng-container
                *ngTemplateOutlet="
                  cellTemplate ? cellTemplate.template : colTemplate;
                  context: { $implicit: row, col: col, defaultTemplate: colTemplate }
                "
              ></ng-container>

              <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="ColumnType.ATTRIBUTES">
                  <app-table-attributes [attributeName]="col.label" [data]="row"></app-table-attributes>
                </ng-container>
              </ng-container>

              <ng-template #colTemplate>
                <pik-ellipsis-text>{{ row[col.name] }}</pik-ellipsis-text>
              </ng-template>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="spacer">
        <td cdk-cell *cdkCellDef="let element" [attr.colspan]="displayedColumns.length"></td>
      </ng-container>

      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns"
        [class.deleted]="row.isDeleted"
        (mousedown)="onRowClickFn(row, $event, 'down')"
        (mouseup)="onRowClickFn(row, $event, 'up')"
        [ngClass]="{
          rowGrey:
            (row?.id === (this.rowService.openDrawerIdSubject$ | async) && row?.id !== null && row?.id !== undefined) ||
            (row?.functionalTypeParameterId === (this.rowService.openDrawerIdSubject$ | async) &&
              row?.functionalTypeParameterId !== null &&
              row?.functionalTypeParameterId !== undefined) ||
            (row?.parameterValueId === (this.rowService.openDrawerIdSubject$ | async) &&
              row?.parameterValueId !== null &&
              row?.parameterValueId !== undefined),
          isEmpty: row?._isEmpty
        }"
      ></tr>
      <tr cdk-row *cdkRowDef="let row; columns: ['spacer']" class="spacer"></tr>
    </table>
  </perfect-scrollbar>

  <app-table-paginator
    *ngIf="paginator"
    [fullCount]="paginator.fullCount"
    [pageIndex]="paginator.pageIndex"
    [limit]="paginator.limit"
    [limitMin]="paginator.limitMin"
    [pageSizesList]="paginator.pageSizesList"
    [entitiesLabel]="entityPhrase"
    (pageNumberChange)="pageChange.emit($event)"
    (pageSizeChange)="pageSizeChange.emit($event)"
  ></app-table-paginator>
</ng-container>

<pik-card *ngIf="!dataSource?.length && !pending">
  <div class="table-wrapper__empty" style="padding: 88px 0">
    <pik-empty-data
      *ngIf="hasActiveFilter && (!paginator || paginator?.fullCount === 0)"
      searchMode
      message="{{ entityPhrase | translate }} {{ 'MAIN_TABLE.NOT_FOUND' | translate }}"
      description="{{ 'MAIN_TABLE.CHANGE_SEARCH' | translate }}"
    >
    </pik-empty-data>

    <div class="empty-message" *ngIf="!pending && !dataSource?.length && !hasActiveFilter">
      <svg-icon src="./assets/icons/list.svg"></svg-icon>
      {{ entityPhrase }} {{ 'MAIN_TABLE.NOT_ADDED' | translate }}
    </div>
  </div>
</pik-card>

<div class="loader-wrapper" *ngIf="pending">
  <pik-loader></pik-loader>
</div>
