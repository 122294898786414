import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaths } from '@common/consts';
import { IHistoryDto } from '@common/dto/HistoryDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryApiService {
  constructor(private http: HttpClient) {}
  public getHistoryById(id: number, date: string): Observable<IHistoryDto[]> {
    return this.http.get<IHistoryDto[]>(`${ApiPaths.History}/GetUniqueParameterById/${id}`, {
      params: new HttpParams().set('startDate', date),
    });
  }
}
