import { Component, Input } from '@angular/core';
import { BindingsFilterParams, IBindingsFilterParams } from '@common/models/filters';
import { ROUTES, ROUTES_PARTS } from '@common/consts';
import { normalizePageParams } from '@common/utils';

import { PermissionService } from '@core/account/services';
import { ConditionLinkModule } from '@shared/components/condition-link';

@Component({
  standalone: true,
  selector: 'app-bindings-link',
  imports: [ConditionLinkModule],
  templateUrl: './bindings-link.component.html',
})
export class BindingsLinkComponent {
  @Input() set params(value: IBindingsFilterParams) {
    if (!value) return;
    const filters = new BindingsFilterParams(value);
    this.link = ['', ROUTES.bindingsList, normalizePageParams(filters.toParams())];
  }

  @Input() set data(value: any) {
    const [data, sourceType, entityType, entityId] = value;
    if (!value) return;
    this.link = ['', ROUTES_PARTS.BINDINGS, sourceType, entityType, entityId];
    this.queryParams = { binding: data.bindingId };
  }

  link: any[];
  queryParams = {};
  constructor(public permissionsService: PermissionService) {}
}
