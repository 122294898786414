import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PikToastService } from '@pik-ui/ng-components';
import { environment } from 'environments/environment';
import { isJson } from '@common/utils';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements ErrorHandler {
  constructor(private _toast: PikToastService, private zone: NgZone, private translateService: TranslateService) {}

  async handleError(error: Error | HttpErrorResponse | any) {
    if (error?.promise && error?.rejection) {
      error = error.rejection;
    }

    if (environment.production) {
      console.error('%O', error);
    } else {
      this._toast.warning({
        title: 'Console error',
        message: `${error.toString().slice(0, 60)}...`,
        horizontalPosition: 'left',
        duration: 30000,
      });

      setTimeout(() => {
        throw error;
      });
    }

    /** Сбрасываем кеш если ошибка чанка */
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
    }

    if (error instanceof HttpErrorResponse) {
      const code = error.status;
      const codeError = await this.translateService.get('ERRORS.ERROR', { code }).pipe(take(1)).toPromise();
      let message = await this.translateService.get('ERRORS.SOMETHING_WRONG').pipe(take(1)).toPromise();
      const exception = isJson(error?.error) ? JSON.parse(error?.error) : error?.error;

      if (exception?.title) {
        message = exception.title;
      } else {
        if (code === 403) {
          message = await this.translateService.get('ERRORS.PERMISSIONS').pipe(take(1)).toPromise();
        }
      }
      const maxLength = 100;
      let duration = 10000;
      let closable = true;
      if (message.length > maxLength) {
        duration = duration * 100;
        this.copyHelpSubscribe$.next(message);
      }

      if (code !== 401) {
        this.zone.run(() => {
          this._toast.show({
            type: 'warning',
            title: codeError,
            message,
            closable,
            duration,
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
          });
        });
      }
    }
  }
  copyHelpSubscribe$ = new Subject();
  copyHelpStream$ = this.copyHelpSubscribe$.pipe(debounceTime(300)).subscribe((message: string) => {
    const span = document.createElement('span');
    span.title = 'Копировать';
    const id = crypto.randomUUID();
    span.id = id;
    span.innerHTML = `<svg class="copy__toast" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.33334 10.0002H2.66668C2.31305 10.0002 1.97392 9.85969 1.72387 9.60964C1.47382 9.35959 1.33334 9.02045 1.33334 8.66683V2.66683C1.33334 2.31321 1.47382 1.97407 1.72387 1.72402C1.97392 1.47397 2.31305 1.3335 2.66668 1.3335H8.66668C9.0203 1.3335 9.35944 1.47397 9.60949 1.72402C9.85953 1.97407 10 2.31321 10 2.66683V3.3335M7.33334 6.00016H13.3333C14.0697 6.00016 14.6667 6.59712 14.6667 7.3335V13.3335C14.6667 14.0699 14.0697 14.6668 13.3333 14.6668H7.33334C6.59696 14.6668 6.00001 14.0699 6.00001 13.3335V7.3335C6.00001 6.59712 6.59696 6.00016 7.33334 6.00016Z"
                stroke="#828282"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>`;
    document.querySelector('.pik-toast').appendChild(span);
    document.getElementById(id).addEventListener('click', async () => {
      message = await this.translateService.get('ERROR_COPY').pipe(take(1)).toPromise();
      navigator.clipboard.writeText(message);
      this.zone.run(() => {
        this._toast.show({
          type: 'success',
          message,
          closable: true,
          duration: 3000,
          horizontalPosition: 'left',
          verticalPosition: 'bottom',
        });
      });
    });
  });
}
