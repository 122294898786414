import { TablePageFilter } from '../table-page-filter-base';
import { INomenclatureKindsFilterParams } from '../index';

export class NomenclatureKindsFilterParams extends TablePageFilter<INomenclatureKindsFilterParams> {
  id: number;
  name: string;
  isUsedInCp: boolean;
  isArchive: boolean;
  measureId: number;

  constructor(filter?: INomenclatureKindsFilterParams) {
    super(filter);
  }

  toObject(): INomenclatureKindsFilterParams {
    return {
      ...super.toObjectBase(),
      id: this.id,
      name: this.name,
      isUsedInCp: this.isUsedInCp,
      isArchive: this.isArchive,
      measureId: this.measureId,
    };
  }
}
