import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alarm-message',
  templateUrl: './alarm-message.component.html',
  styleUrls: ['./alarm-message.component.scss'],
})
export class AlarmMessageComponent implements OnInit {
  @Input() type: 'error' | 'warning';
  constructor() {}

  ngOnInit(): void {}
}
