import { ImplicitRedirectComponent } from './implicit-redirect';
import { ContentLayoutComponent } from './content-layout';
import { StartPageComponent } from './start-page';
import { Page404Component } from './page-404';
import { ReadOnlyPageComponent } from './read-only-page/read-only-page.component';

export const coreComponents = [
  ImplicitRedirectComponent,
  ContentLayoutComponent,
  StartPageComponent,
  Page404Component,
  ReadOnlyPageComponent,
];
