import { Pipe, PipeTransform } from '@angular/core';
import { IPreparedHistoryObjectValue } from '@common/dto/HistoryDto';
import { HistoryActions } from '@common/interfaces/history.interface';
import { TranslateService } from '@ngx-translate/core';

function getActionTranslate(action: HistoryActions): string {
  switch (action) {
    case HistoryActions.add:
      return 'HISTORY_MODULE.ACTIONS.ADD';
    case HistoryActions.edit:
      return 'HISTORY_MODULE.ACTIONS.EDIT';
    case HistoryActions.delete:
      return 'HISTORY_MODULE.ACTIONS.DELETE';
    case HistoryActions.archive:
      return 'HISTORY_MODULE.ACTIONS.ARCHIVE';
    case HistoryActions.restore:
      return 'HISTORY_MODULE.ACTIONS.RESTORE';
    default:
      return ``;
  }
}

@Pipe({
  name: 'historyChangeItem',
})
export class HistoryChangeItemPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
  transform(value: IPreparedHistoryObjectValue): unknown {
    // console.log(value);
    if (!value.action) return null;
    return this.translate.instant(getActionTranslate(value.action), {
      authorLink: 'test',
      authorName: value.author || '""',
      fieldName: value.fieldName || '""',
      newValue: value.newValue || '""',
      oldValue: value.oldValue || '""',
    });
  }
}
