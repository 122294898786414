import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TrackByFunction,
  ViewChildren,
} from '@angular/core';
import { TableColumnDef } from '@shared/modules/table-builder';
import { ColumnType } from '../table-builder/models/table-columns';
import { FmTableCellTemplateDirective, FmTableHeaderTemplateDirective } from './directives';
import { SortOrder } from '@common/interfaces';
import { normalizePageParams } from '@common/utils';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { RowService } from '@services/row.service';
import { ClickType } from '@common/enums/mouse.enum';
import { Router } from '@angular/router';
import { ROUTES_PARTS } from '@common/consts';

interface TablePaginatorDef {
  fullCount: number;
  pageIndex: number;
  limit: number;
  limitMin: number;
  pageSizesList: number[];
}

@Component({
  selector: 'fm-table',
  templateUrl: './fm-table.component.html',
  styleUrls: ['./fm-table.component.scss'],
})
export class FmTableComponent {
  @Input() paginator: TablePaginatorDef;
  @Input() pending: boolean;
  @Input() hasActiveFilter: boolean;
  @Input() entityPhrase = 'MAIN_TABLE.ENTITY_PHRASE';
  @Input() sortBy: string;
  @Input() sortOrder: SortOrder = SortOrder.Ascending;
  clickedCell = null;
  @Input()
  set columns(cols: TableColumnDef[]) {
    if (!cols) {
      throw new Error('Column description not passed');
    }

    if (!Array.isArray(cols)) {
      throw new Error('Column descriptions must be an array and be of type TableColumnDef[]');
    }

    this._columns = cols.sort((a, b) => a.orderPosition - b.orderPosition);
    this.displayedColumns = this._columns.map((c) => c.name);
  }
  get columns() {
    return this._columns;
  }

  @Input() dataSource: any[] = [];
  @Input() trackBy: TrackByFunction<any>;

  @Output() sortChange = new EventEmitter();
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() onRowClick = new EventEmitter<any>();

  @ContentChild(FmTableHeaderTemplateDirective)
  headerTemplate: FmTableHeaderTemplateDirective;

  @ContentChild(FmTableCellTemplateDirective)
  cellTemplate: FmTableCellTemplateDirective;

  @ViewChildren(PerfectScrollbarComponent)
  _scrollbar: QueryList<PerfectScrollbarComponent>;

  SortOrder = SortOrder;
  ColumnType = ColumnType;
  displayedColumns: string[] = [];
  hideScrollingOverlay: boolean;

  areRowLinks = false;
  excludedUrlLiks = [
    ROUTES_PARTS.ROLES,
    ROUTES_PARTS.STANDARDS,
    ROUTES_PARTS.PROJECTS,
    ROUTES_PARTS.DISCIPLINES,
    ROUTES_PARTS.ATTRIBUTES,
    ROUTES_PARTS.CATEGORIES,
    ROUTES_PARTS.UNIT_TYPES,
    ROUTES_PARTS.PARAMETERS,
    ROUTES_PARTS.UNIQUE_PARAMETERS,
    ROUTES_PARTS.FUNCTIONAL_TYPES_PARAMETERS,
    ROUTES_PARTS.SPECIFICATIONS,
    ROUTES_PARTS.MATERIALS_PARAMETERS,
    ROUTES_PARTS.PROJECTS,
    ROUTES_PARTS.LOAD_LOGS,
  ];

  excludedUrlSubLiks = [ROUTES_PARTS.FUNCTIONAL_TYPES, ROUTES_PARTS.FAMILIES, ROUTES_PARTS.MATERIALS];

  dontOpenDrowerList = ['и ещё', 'Скрыть'];

  normalizePageParams = normalizePageParams;

  private _columns: TableColumnDef[] = [];

  constructor(public rowService: RowService, private router: Router) {
    this.areRowLinks = !(
      this.excludedUrlLiks.includes(this.router.url.split('/')[1]) ||
      (this.excludedUrlSubLiks.includes(this.router.url.split('/')[1]) &&
        !isNaN(parseInt(this.router.url.split('/')[2])))
    );
  }

  trackByIndex(index) {
    return index;
  }

  onRowClickFn(row, event: MouseEvent, type: 'up' | 'down'): void {
    if (
      this.dontOpenDrowerList.some((text) =>
        event.target['innerHTML'].toLowerCase().trim().startsWith(text.toLowerCase().trim()),
      )
    )
      return;

    if (!this.areRowLinks && type === 'down') return;
    if (event.button === ClickType.rightClick) return;
    setTimeout(() => {
      this.onRowClick.emit([row, this.clickedCell, event.button]);
    }, 10);
  }

  onCellLinkClick(event: Event, col) {
    this.clickedCell = col;
    if (!this.rowService.link) {
      event.stopPropagation();
      event.preventDefault();
    }
    setTimeout(() => {
      this.rowService.link = null;
    }, 350);
  }
}
