<fieldset>
  <span class="mr2">{{ 'HISTORY_MODULE.FROM' | translate }}</span>
  <pik-datepicker [formControl]="date" inverse [max]="today"></pik-datepicker>
  <span class="ml1">{{ 'HISTORY_MODULE.TO' | translate }}</span>
  <span class="ml1 fwb">{{ 'HISTORY_MODULE.CURRENT_DATE' | translate }}</span>
</fieldset>

<pik-loader class="loader" *ngIf="pending; else content"></pik-loader>

<ng-template #content>
  <ng-container *ngIf="historyData?.length; else noHistory">
    <div class="history-item" *ngFor="let item of historyData">
      <div>
        <span class="flex">
          <svg-icon class="calendar mr1" src="./assets/icons/calendar.svg"></svg-icon>
          <pik-tag class="mb1" color="#f2f2f2" textColor="#000">
            {{ item.changeDate }}
          </pik-tag>
        </span>
        <div class="change__time-array" *ngFor="let changeTime of item.changeTimes">
          <pik-tag class="change__time-item" color="#f2f2f2" textColor="#000">
            {{ changeTime.time }}
          </pik-tag>
          <div>
            <div
              class="change__time__item-value"
              [innerHTML]="value | historyChangeItem"
              *ngFor="let value of changeTime.values"
            ></div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</ng-template>

<ng-template #noHistory>
  <div class="mt2">{{ 'HISTORY_MODULE.NO_HISTORY' | translate }}</div>
</ng-template>
