<ng-container *ngIf="access; else withoutLink">
  <a [routerLink]="link" [queryParams]="queryParams" appLinkTab (click)="onClick($event)">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </a>
</ng-container>

<ng-template #withoutLink>
  <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
</ng-template>

<ng-template #tempOutlet>
  <ng-content></ng-content>
</ng-template>
