export class MessageDto {
  id: number;
  userId: number;
  fio: string;
  email: string;
  message: string;
  isSystem: boolean;
  chatMessageType: number;
  messageObjectId: number;
  created: Date | string;
  additionalReceiverIds: number[];
}

export interface ChatParams {
  messageObjectId?: number;
  chatMessageType?: number;
  chatMessageTypeId?: number;
}
