import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IHistoryDto, IPreparedHistoryObject } from '@common/dto/HistoryDto';
import { HistoryActions, HistoryType } from '@common/interfaces/history.interface';
import { HistoryApiService } from '@services/api/history-api.service';
import { dateToISOLikeButLocal } from '@shared/utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnDestroy {
  _destroy$ = new Subject();
  date = new FormControl(null);
  historyData: IPreparedHistoryObject[] = null;
  pending = false;
  today = new Date();

  __data: { id: number; mode: HistoryType } = null;
  @Input() set data(value: { id: number; mode: HistoryType }) {
    this.__data = value;
    if (value) {
      this.date.setValue(new Date().setMonth(new Date().getMonth() - 1));
    }
  }
  get data(): { id: number; mode: HistoryType } {
    return this.__data;
  }

  constructor(private historyApiService: HistoryApiService) {
    this.date.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((date) => {
      this.setData(this.data.id);
    });
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  setData(id: number): void {
    if (!id) return;
    this.pending = true;
    this.historyApiService.getHistoryById(id, dateToISOLikeButLocal(this.date.value)).subscribe({
      next: (resp) => {
        this.historyData = [];
        resp.forEach((item) => {
          this.historyData.push({
            changeDate: item.changeDate,
            changeTimes: item.changes.map((change) => ({
              time: change.time,
              values: change.values.length
                ? change.values.map((value) => ({
                    action: change.action,
                    author: change.author,
                    ...value,
                  }))
                : [{ action: change.action, author: change.author }],
            })),
            //если потребуется фильтрвать с пустыми занчениями
            // .filter((change) => change.values.length),
          });
        });
      },
      complete: () => (this.pending = false),
    });
  }
}
