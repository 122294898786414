import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { TableColumnDef } from './table-columns';

export interface IRowClickEvent<T = any> {
  nativeEvent: MouseEvent;
  column: TableColumnDef;
  data: T;
}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class TableBase<T> {
  @Input()
  dataSource: T[];

  @Output()
  rowClick = new EventEmitter<IRowClickEvent<T>>();

  @Output()
  sortChange = new EventEmitter();

  public columns: TableColumnDef[] = [];
  public displayedColumns: string[] = [];

  constructor(columns: TableColumnDef[], displayedColumns: string[]) {
    this.dataSource = [];
    this.columns = columns;
    this.displayedColumns = displayedColumns;
  }

  trackByIndex(index) {
    return index;
  }
}
